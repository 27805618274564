<template>
  
  <v-virtual-scroll
      :items="courseDatas"
      :item-height="50"
      height="300"
      style="border:  3px solid #C4C4C4; border-radius: 10px;"
    >
      <template v-slot:default="courseData">
        
        <v-list-item style="font-size: 1.5rem; font-weight:100;">
          <div v-if="courseData.item.prefixCourseNumber.includes('fac')"> ทุกวิชาในคณะ   {{ courseData.item.facultyTitle }}</div>
          <div v-else>
          {{ courseData.item.prefixCourseNumber }} &nbsp; - {{ courseData.item.title }}
          </div>
        </v-list-item>
      </template>
    </v-virtual-scroll>
</template>

<script>
export default {
    name: 'ScrollContents',
    props: {
        courseDatas: Array
    },
    
}
  </script>
  
  