<template>
    <v-row >
      <v-col lg="4" md="4" sm="4" style="background-color: #F6F2FF;">

        <div >
          <v-img
            alt="Vuetify Logo"
            class="shrink"
            style="margin-top:50%; margin-bottom: 50%; "
            src="../assets/images/admin-info-banner.png"
            transition="scale-transition"
            width="503"
          />

        </div>
      </v-col>
      <v-col lg="8" md="8" sm="8" style="background-color: #ffffff;">
        <div v-if="verifyStatus">
          <v-container style="padding: 20%; align-items: center; justify-content: center;">
              <div style="display:flex; align-items: center; justify-content: center;">
                <v-col >
                  <v-row style="margin-left: auto; margin-right:auto;  align-items: center; justify-content: center;">
                        <i style="color:#30B401" class="fas fa-check-circle fa-10x"></i>      
                  </v-row>

                  <v-row  style="align-items: center; justify-content: center;">
                    <div class="align-self-center mt-5">
                      <div class="inner" style="font-size: 2.6rem;">
                        <b>ยืนยันข้อมูลสำเร็จ !</b>
                      </div>
                    </div>
                  </v-row>

                  <v-row style="align-items: center; justify-content: center;">
                    <div class="align-self-center">
                      <div class="inner" style="font-size: 1.5rem;">
                        ขอบคุณสำหรับการยืนยันข้อมูล
                      </div>
                    </div>
                  </v-row>
                  

                  <v-row style="align-items: center; justify-content: center;">
                    <div class="align-self-center mt-3">
                      <div class="outner" style="font-size: 0.75rem;">
                        สามารถติดตามข่าวสารเกี่ยวกับแอดมินได้ที่ 
                      </div>
                    </div>
                  </v-row>
                  <v-row style="align-items: center; justify-content: center;">
                    <div class="align-self-center">
                      <div class="outner" style="font-size: 0.75rem;">
                      Microsoft teams group : Admin Course Portal Management 
                      </div>
                    </div>
                  </v-row>
                </v-col>
              </div>
          </v-container>
        </div>
        <div v-else>
          <v-container style="padding: 10%;">
            <v-row class="mb-5">
              <label style="font-size: 3rem;"><b>ข้อมูลผู้ดูเเล</b></label>
            </v-row>
            <v-row class="mb-3">
              <h1 style="font-size: 1.5rem;"><b>ชื่อ-สกุล</b></h1>
            </v-row>
            <v-row class="mb-5">
              <h1 style="font-size: 1.5rem; font-weight: 200;">{{userData.name}} </h1>
            </v-row>

            <v-row class="mb-3">
              <h1 style="font-size: 1.5rem;"><b>อีเมล</b></h1>
            </v-row>
            <v-row class="mb-5">
              <h1 style="font-size: 1.5rem; font-weight: 200;">{{userData.email}}</h1>
            </v-row>
            <v-row class="mb-3">
              <h1 style="font-size: 1.5rem;"><b>หน่วยงาน</b></h1>
            </v-row>
            <v-row class="mb-5">
              <h1 v-if="grant.grantOnCourses[0].facultyTitle != undefined  " style="font-size: 1.5rem; font-weight: 200;">{{grant.grantOnCourses[0].facultyTitle}}</h1>
              <h1 v-else style="font-size: 1.5rem; font-weight: 200;">ไม่พบข้อมูลหน่วยงาน</h1>

            </v-row>
            <v-row class="mb-5" >
              <div class="align-self-end mt-5">
                <div class="w-50">
                  <h1 class="m-2" style="font-size: 1.5rem;"><b>กลุ่มกระบวนวิชาที่ดูแล </b><i class="fas fa-circle-exclamation sm"></i></h1>
                </div>
              </div>
              <div class="align-self-end mt-5">
                <div class="w-50">
                  <v-alert             
                    elevation="2"
                    width="20%"
                    style="position: absolute; transform: translate(2%, -100%);"
                    color="#3E4555"
                  >
                    <a style="color: #ffffff;" > กลุ่มรหัสวิชาคือ รหัส 3 ตัวหน้าของกลุ่มวิชานั้น เช่น
                    <a style="color: #FFD233;" >951</a>202, <a style="color: #FFD233;" >951</a>371</a>
                  </v-alert>               
                </div>
              </div>
             

            </v-row>
            <v-row class="mb-5">
              <ScrollContents :courseDatas="grant.grantOnCourses"/>
            </v-row>
            
            <div style="display:flex; align-items: left; justify-content: start;">
              <div class="align-self-center">
                <div class="inner" style="font-size: 1.2rem; color: crimson;">
                  *หากข้อมูลผิดพลาด กรุณา<a href="https://cmu.to/cpm-admin-problem" target="blank">ติดต่อเจ้าหน้าที่</a>
                </div>
              </div>
              
            </div>
            <div style="display:flex; align-items: center; justify-content: center; margin-top: 2rem;">
              <v-checkbox v-model="checked"></v-checkbox>
              <div class="align-self-center">
                <div class="inner" style="font-size: 1.2rem;">
                  ข้อมูลทั้งหมดถูกต้อง
                </div>
              </div>
              
            </div>
            <div v-if="checked">
              <div style="display:flex; align-items: center; justify-content: center;">
                <v-btn
                    depressed
                    color="primary"
                    width="60%"
                    @click="onClickVerify()"
                  ><h2 class="m-5">ยืนยันข้อมูล</h2></v-btn>
              </div>
            </div>
    

          </v-container>
        </div>
        
      </v-col>
    </v-row>
    
</template>

<script>
  import ScrollContents from '../components/ScrollContents.vue'
  import apiGatewayService from '../services/apiGatewayService'

  export default {
  name: 'Activated',
  components: {
    ScrollContents
  },
  data(){
    return {
      checked : false,
      verifyStatus : false,
      grant :{
        grantOnCourses : [{
          facultyTitle: undefined
        }]
      },
      counter : 5
    }
  },
  created(){
    this.getGrantAdmin(localStorage.getItem('accessToken'))

  },
  computed:{
    userData: {
      get () {
          return this.$store.state.userData
      }
    },
  },
  methods:{
    onClickVerify () {
      this.putActivateAdmin(localStorage.getItem('accessToken'))
      
      // let interval = setInterval(this.timer, 1000);

      // setTimeout(() => {
      //   // console.log("redirect to home ")
      //   clearInterval(interval);

      //   // this.$router.push(`/`)
      // }, 5000 )
    },
    // timer(){
    //     if (this.counter > 0){
    //       this.counter = this.counter -1 ;
    //     }
    // },
    async getGrantAdmin(accessToken){
      const grantAdmin = await apiGatewayService.getGrantAdmin(accessToken);
      if(grantAdmin){
        this.grant = grantAdmin
        // console.log("grantAdmin:>" , this.grant )
      }

    },
    async putActivateAdmin(accessToken){
      let regBody = {
        "mode" : "update",
        "isApprove": true
      }
      // console.log("test :>", accessToken , regBody , this.grant._id)
      const updated = await apiGatewayService.putGrantAdmin( accessToken , regBody , this.grant._id);
      if(updated){
        this.verifyStatus = true;
      }

    }

  }
  
}
</script>